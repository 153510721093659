.alert-success {
  & b {
    &.bold-link {
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
