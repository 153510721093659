@import '../../../../scss/variables.scss';

.add-beacon-asset-header {
  & h4 {
    color: var(--primary);
  }

  & i {
    color: var(--dark);
    top: 0;
    right: 0;
  }
}
