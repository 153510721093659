@import '../../scss/variables.scss';

.cell-button {
  background-color: #fcfcfd;
  cursor: pointer;
  transition: box-shadow 0.15s, transform 0.15s;
}

.cell-button:hover {
  box-shadow: rgba(56, 56, 56, 0.4) 0 1px 1px 0.1px, rgba(56, 56, 56, 0.4) 0 1px 1px 0.1px;
  transform: translateY(0);
  z-index: 1;
}

// Asset Name Input

input#assetName:disabled {
  border: none;
}

input#assetName {
  border: none;
  border-bottom: solid 1px;
  background: none;
  color: var(--dark) !important;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:focus-visible {
    outline: none;
    background: none;
  }
}

.advanced-asset-asset-header {
  .asset-name-form {
    width: 85%;

    & button {
      float: right;
    }
  }
}

.asset-name-form {
  width: 100%;

  & button {
    float: right;
  }
}

p#errorMsg {
  color: rgb(225, 19, 19);
  font-size: 0.55em;
  margin: 0;
  position: absolute;
  top: 48px;
}
