@import '../variables.scss';

nav.sidenav {
  height: 100%;
  position: fixed;
  z-index: 1;
  bottom: -75;
  top: 0;
  left: 0;
  background-color: var(--white);
  overflow-x: hidden;
  transition: 0.5s;
}

.nav-links {
  margin-top: 100px;
}
