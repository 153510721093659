@import '../../../scss/variables.scss';

.user-select-msg {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & i {
    color: var(--primary);
  }
}
