@import './../../scss/variables.scss';

.map-input {
  color: var(--dark) !important;
}

.map-detail-container {
  overflow: hidden;

  & .row {
    padding: 5px 0;
  }

  & .btn-group {
    gap: 4px;
  }
}

.location-map-container {
  height: 90%;
}
