.sonar-tour-modal {
  &.fade-in {
    animation: fade-and-slide 1s ease-in-out;
  }

  &.fade-out {
    animation: fade-and-slide-out 0.3s ease-in-out;
  }

  @keyframes fade-and-slide-out {
    from {
      opacity: 1;
      transform: translateY(0);
    }

    to {
      opacity: 0;
      transform: translateY(-50px);
    }
  }

  & .modal-dialog {
    width: 380px;
    margin-top: 7%;
  }

  & .modal-content {
    background: var(--primary);
    background-image: url('../../../img/network-texture.png');
    border: none;
    border-radius: 17px;
  }

  & .modal-body {
    padding: 50px 0;
    align-self: center;
  }

  & .logo {
    height: 80px;
    margin-bottom: 10px;
  }

  & .data-image {
    height: 200px;
  }

  & .tour-title {
    color: #fff;
    font-size: 1.3em;
    font-weight: 200;
  }

  & button {
    border: none !important;

    &#start {
      font-size: 1.3em;
      padding-bottom: 15px;

      &:hover {
        color: #fff;
      }

      &:active {
        color: #fff;
      }
    }

    &#skip {
      font-size: 1.1em;
      font-weight: 200;
    }

    color: #fff;
  }

  & .circle {
    margin-top: 1px;
    width: 25px;
    height: 25px;
    background-color: transparent;
    border: 1px solid white;
    border-radius: 50%;
    position: relative;
  }

  & .carrot {
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-left: 10px solid white;
    position: absolute;
    top: 50%;
    left: 80%;
    transform: translate(-100%, -50%);
  }
}

.__floater__arrow {
  left: 2px !important;
}

// Custom tooltip

.custom-tool-tip-tour {
  & .c-tool-tip-blue {
    display: flex;
    justify-content: space-between;
    color: #ffffff;
    background: var(--primary);
    background-image: url('../../../img/network-texture.png');
    border-radius: 15px 15px 0 0;
  }
  & .c-tool-tip-title {
    padding: 15px 30px 7px;

    font-size: 1.2em;
  }
  & .c-tool-tip-exit {
    padding: 12px;

    & i {
      transform: rotate(45deg);
    }
  }
  & .c-tool-tip-content {
    background: #ffffff;
    padding: 15px;
    border-radius: 0 0 15px 15px;
  }
  & .c-tool-tip-footer {
    display: flex;
    justify-content: right;
    gap: 10px;
    & .c-tool-back {
      width: 22px;
      height: 22px;
      color: #ffffff;
      background-color: var(--nav-text);
      border-radius: 50%;
      transform: rotate(90deg);
      & i {
        padding-top: 6px;
        padding-left: 5px;
      }
    }
    & .c-tool-next {
      width: 22px;
      height: 22px;
      color: #ffffff;
      background-color: var(--nav-text);
      border-radius: 50%;
      transform: rotate(-90deg);
    }
    & i {
      padding-top: 6px;
      padding-left: 5px;
    }
  }
  & li::marker {
    color: var(--alt1);
  }
}
