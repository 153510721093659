.contact-list {
  padding: 16px;
  min-width: 400px;
  min-height: 300px;
}

.delivery-schedule {
  min-width: 200px;
}

.cursor-pointer {
  cursor: pointer;
}

#contacts.dropdown-menu {
  max-height: 160px !important;
}
