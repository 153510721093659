@keyframes fade-in {
  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.info {
  opacity: 1;
  animation: fade-in 1s ease-in;
}

.profile-pic {
  background-color: var(--nav-text);
  border-radius: 50%;
  padding: 3em;
  margin-right: 2em;
  position: relative;
}

.user-initials {
  color: var(--white);
  font-weight: normal;
  height: auto;
  position: absolute;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 5px;
  overflow: hidden;
}

.edit-profile-pic {
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--primary);
  border-radius: 50%;
  padding: 0.5em 0.5em;
}

.profile-pic:hover > .edit-profile-pic {
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}
