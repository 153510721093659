@import '../../scss/variables.scss';

.info-icon {
  color: var(--dark);
}

span.badge.badge-pill {
  margin: 0.7em;
  padding: 0.9em;
}

.id-key {
  font-size: 1.5em;
  margin-left: 10px;
}

.id-value {
  font-size: 1.5em;
  margin-left: 10px;
}

.swal2HtmlContainer {
  margin: 0 1.6em 0.3em !important;
}

.swal2-submitData {
  display: block;
  position: relative;
  top: 0;
  font-weight: bold;
  word-wrap: break-word;
  font-size: 27px;
  padding-bottom: 20px;
  padding-top: 0.2em;
}
