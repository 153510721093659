@import '../../scss//variables.scss';

// AdvancedAsset
.card-last-reading {
  min-height: 100%;
}

.advanced-asset-main-card {
  width: 85%;
}

.asset-toggle-switch {
  & input:checked {
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
  }
}

#normalize-checkbox:checked {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
}

// Beacons Table
.adv-asset-beacons-table {
  color: var(--dark);
}

// Summary Card
@media (max-width: 992px) {
  #card-summary-container {
    padding-bottom: 3rem;
    flex-direction: row !important;
    justify-content: space-between;

    & .card {
      margin-bottom: 0 !important;
      width: 30%;
    }
  }
}

.card-subtitle a {
  color: var(--primary) !important;
}

// Graph Container
@media (max-width: 1575px) {
  .advanced-graph-container {
    flex-flow: wrap;
    margin-top: 20px !important;
  }
}

@media (max-width: 1000px) {
  .advanced-asset-asset-header {
    flex-flow: wrap;
  }
}

// CheckboxDropdown

.dropdown-item {
  padding: 0.25rem 0.75rem !important;
}

.beacon-list-empty {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  & .sonarIcon-beacon {
    color: var(--primary);
  }
}

.advanced-asset-right-button-container {
  display: flex;
  flex-direction: column;
  gap: 60px;
  padding-top: 10%;
  padding-left: 6%;

  & button {
    color: var(--white);
    // background-color: var(--primary);
    // cursor: pointer !important;
    padding: 10px 20px;

    &:hover {
      color: var(--white);
      // background-color: var(--primary);
    }

    &:active {
      color: var(--white) !important;
      // background-color: var(--primary) !important;
    }
  }
}

.statistics-card-wrapper {
  overflow-x: auto;

  &::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background: rgb(253, 253, 253);
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 6px;
  }

  & .card {
    width: max-content;
    margin-left: 10px;
    margin-right: 10px;
  }

  & thead {
    border-bottom: 2px solid #c3d1d9;
  }

  & table {
    width: -webkit-fill-available;
  }

  & .ability-label {
    padding-left: 20px;
    text-wrap: nowrap;
  }

  & .ability-stats {
    padding-left: 15px;
    padding-right: 30px;
  }
}

.advanced-charting.row > * {
  max-width: none !important;
}

.advanced-asset-all-cards-wrapper {
  & .date-headers {
    background-color: var(--white);
    width: 85%;
    height: 50px;
    border: 1px solid var(--secondary);
    border-radius: 5px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;

    & h6 {
      color: var(--dark);
    }
  }

  & .statistics-card-wrapper {
    display: flex;
    justify-content: space-between;
    width: 85%;
    margin-left: 0;
  }

  & .statistic-card {
    max-width: fit-content;
  }
}

.all-run-checkbox {
  background-color: #f4f7f9;
  margin-bottom: 0 !important;
  padding-left: 2.2em !important;
  padding-top: 0.5em;

  & .form-check-label {
    font-weight: 500;
  }
}
