#placeScreenGroupedGrid > div.table-responsive {
  min-height: 600px;
  height: 65vh;
}

@media (max-width: 1750px) {
  .place-grid {
    margin-bottom: 25px;
  }
}

@media (min-width: 1750px) {
  .place-grid,
  .place-map {
    width: 50% !important;
  }
}
