@import '../../../scss/variables.scss';

// Header CSS

:root {
  --nav-height: 50px;
}

// Left Thinaer Header Logo

header.header {
  position: fixed;
  background-color: transparent;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
}

.header-left {
  padding-left: 20px;
  z-index: 999;
  pointer-events: auto;

  & svg {
    fill: var(--primary);
    height: 45px;
  }

  & button {
    padding: 3px;
  }

  & img {
    padding-left: 10px;
  }
}

// Right header icons

.header-icon {
  font-size: 1.6em;
}

.logo {
  transition: 0.5s;
}

.header-list {
  list-style: none;
  margin-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: auto;

  & .text {
    color: var(--dark);
  }

  & li {
    display: inline-block;

    & button {
      color: var(--nav-color);
    }
  }
}

.popover {
  min-width: 200px;
}

.application-popover {
  & .popover-body {
    overflow: auto;
    max-height: 400px;
  }
}

// NavToggle

.nav-hamburger {
  color: var(--primary);
}

// Create New Dropdown

.create-new-dropdown {
  & .list-group-item-action:hover {
    background-color: var(--dropdown-hover);
  }

  & i {
    color: var(--primary);
  }
}
