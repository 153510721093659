@import '../../scss/variables.scss';

.toggles {
  position: absolute;
  margin-left: 55px;
  margin-top: 10px;
  z-index: 1000;
}

.toggles-body {
  padding: 8px;
  background-color: var(--body);
  border-radius: 1em !important;
}

.toggle-btn {
  border: none !important;
  padding: none !important;
  background-color: transparent !important;
  cursor: pointer;
}
