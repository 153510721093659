@import './../../scss/variables.scss';

span.badge.badge-pill {
  margin: 0.7em;
  padding: 0.9em;
}

.keyword-text {
  font-size: 1.5em;
  margin-left: 10px;
}

input.keyword {
  background-color: var(--primary) !important;
  border: none;
  border-radius: 2em 0.5em 0.5em 2em;
  color: var(--body) !important;
  font-size: 1.5em;
  margin-left: 2px;
  padding-left: 10px;
  outline: none;

  &::placeholder {
    color: var(--white);
  }
}
