.edit-delete-cellRenderer {
  display: none;
}

.show.edit-delete-cellRenderer {
  display: inline;
}

.ag-row-hover .edit-delete-cellRenderer {
  display: inline;
}
