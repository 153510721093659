@import './../../../scss/variables.scss';

.dock {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  background-color: var(--white);
  color: var(--primary);
  transition: width 0.5s, min-width 0.5s, opacity 0.75s;
  opacity: 1;
  overflow: auto;
  box-shadow: var(--box-shadow-left);
  border-radius: 1em 0 0 1em;
}

.dock-close {
  opacity: 0;
  transition: width 0.5s, min-width 0.5s, opacity 0.75s;
  box-shadow: none;
  border: none;
}

.dock .text {
  color: var(--nav-text);
}
