@import '../../scss/variables.scss';

.about-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-flow: column;

  & .list-group-item {
    background-color: var(--white);

    & span.text {
      color: var(--dark);
    }

    & a.fw-bold {
      color: var(--primary);
    }

    & .card-img-top {
      height: 150px;
      width: 150px;
      border-radius: 25px;
      box-shadow: var(--box-shadow-right);
    }

    & .mobile-msg {
      display: flex;
      width: 45%;
      text-align: center;
      align-items: center;
      justify-content: center;
    }

    & .app-icons {
      height: 150px;
      width: 150px;

      & .apple-href {
        display: inline-block;
        overflow: hidden;
        border-radius: 13px;
      }
    }

    &.footer-items {
      & .footer-container {
        width: 100%;
        flex-grow: 1;
        flex-shrink: 1;
        min-height: 200px;
      }

      & span.text {
        display: flex;
        text-align: center;
        align-items: center;
        justify-content: center;
        width: 100%;
      }

      & .hipaa-eula-container {
        & a {
          color: var(--dark);
        }

        & span.text {
          width: auto;
        }
      }

      & .blue-social-box {
        background-color: var(--c-primary);
        height: 150px;
        width: 150px;
        border-radius: 25px;
        box-shadow: var(--box-shadow-right);

        & a {
          color: var(--dark);

          & i {
            color: var(--c-white);
            cursor: pointer;
          }
        }

        & span.text {
          color: var(--c-white);
        }
      }
    }
  }
}

.app-icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.vertical {
  width: 300px;
  flex-direction: column;
}

.android {
  width: 175px;
  border-radius: 13px;
}

.apple {
  width: 175px;
  border-radius: 13px;
  padding: 11px;
}
