@import '../../../../../scss/variables.scss';

.back-btn {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.back-btn:hover {
  color: var(--white) !important;
  background-color: var(--primary) !important;
}

.next-btn {
  color: var(--white) !important;
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
}

.next-btn:hover {
  color: var(--primary) !important;
  background-color: var(--white) !important;
  border: 1px solid var(--primary) !important;
}

.next-btn:disabled,
.next-btn[disabled] {
  color: var(--white);
  background-color: var(--c-primary);
  border: 1px solid var(--c-primary);
}

.next-btn:disabled:hover,
.next-btn[disabled]:hover {
  color: var(--c-primary);
  background-color: var(--white);
  border: 1px solid var(--c-primary);
}
