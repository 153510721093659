.edit {
  display: inline;
  visibility: hidden;
}

.show.edit {
  display: inline;
  visibility: visible;
}

.ag-row-hover .edit {
  display: inline;
  visibility: visible;
}

.empty {
  display: inline;
  visibility: hidden;
}

.show.empty {
  display: inline;
  visibility: visible;
}

.ag-row-hover .empty {
  display: inline;
  visibility: visible;
}
