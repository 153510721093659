@import '../../../scss/variables.scss';

.cg-text {
  color: var(--coverage-grey);
}

.cg-table-highlight {
  color: var(--c-coverage-grey);
  background-color: var(--c-primary);
}

.cg-table-highlight-default {
  color: var((--coverage-grey));
}

.cg-table-highlight-default:hover {
  color: var(--c-primary);
}

.cg-table-col {
  color: var(--coverage-grey);
}

.cg-table-colClicked {
  color: var(--c-primary);
}

.bread-crumbs-table-container {
  & .card {
    margin-bottom: 16px;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;

    & .number-input-container {
      padding-top: 8px;
    }

    @media (max-width: 1100px) {
      & .date-number-input-container {
        display: contents !important;

        & .number-input-container {
          padding-top: 30px;
        }
      }
    }
  }
}

.restart-sequence-btn {
  margin-bottom: 16px;
}

.slider-container {
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 16px;
  width: 60%;
}
