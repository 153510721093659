.trigger-res-modal {
  width: 700px;
  margin: 60px auto auto;
  border-radius: 20px;
}

@media (max-width: 800px) {
  .trigger-res-modal {
    width: 600px;
  }
}
