@import '../variables.scss';

// Use the variant attribute on all Buttons and Icons

// --Primary Buttons --
// --(Use for buttons that have to be clicked or need to be shown as selected)--

.btn-primary {
  background-color: var(--primary) !important;
  color: var(--white) !important;
  border: 1px solid var(--primary) !important;
  padding: 6px;
  box-shadow: none !important;
  outline: none !important;
}

.btn-primary:hover {
  text-decoration: underline;
  box-shadow: none !important;
  outline: none !important;
}

.btn-primary:focus {
  text-decoration: underline;
  box-shadow: none !important;
  outline: none !important;
}

.btn-primary:active {
  border: 1px solid var(--primary) !important;
  box-shadow: none !important;
  outline: none !important;
}

.btn-primary:disabled {
  background-color: var(--nav-text) !important;
  color: var(--white) !important;
  border: 1px solid var(--nav-text) !important;
  box-shadow: none !important;
  outline: none !important;
}

// --Secondary Buttons --
// --(Use for buttons that dont have to be clicked)--

.btn-secondary {
  background-color: var(--white) !important;
  color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  padding: 6px;
  box-shadow: none !important;
  outline: none !important;
}

.btn-secondary:hover {
  color: var(--white) !important;
  background-color: var(--primary) !important;
  text-decoration: underline;
  box-shadow: none !important;
  outline: none !important;
}

.btn-secondary:focus {
  text-decoration: underline;
  box-shadow: none !important;
  outline: none !important;
}

.btn-secondary:active {
  border: 1px solid var(--primary) !important;
  box-shadow: none !important;
  outline: none !important;
}

.btn-secondary:disabled {
  background-color: var(--white) !important;
  color: var(--nav-text) !important;
  border: 1px solid var(--nav-text) !important;
  box-shadow: none !important;
  outline: none !important;
}

// --Danger Buttons --
// --(Use for primary delete buttons or buttons that have permanant consequences)--

.btn-danger {
  background-color: var(--white) !important;
  color: var(--danger) !important;
  border: 1px solid var(--danger) !important;
  padding: 6px;
  box-shadow: none !important;
  outline: none !important;
}

.btn-danger:hover {
  background-color: var(--danger) !important;
  color: var(--white) !important;
  border: 1px solid var(--danger) !important;
  text-decoration: underline;
  box-shadow: none !important;
  outline: none !important;
}

.btn-danger:focus {
  text-decoration: underline;
  box-shadow: none !important;
  outline: none !important;
}

.btn-danger:active {
  border: 1px solid var(--danger) !important;
  box-shadow: none !important;
  outline: none !important;
}

.btn-danger:disabled {
  background-color: var(--nav-text) !important;
  color: var(--white) !important;
  border: 1px solid var(--nav-text) !important;
  box-shadow: none !important;
  outline: none !important;
}

// --Light Buttons --
// --(Use for buttons that you dont want to draw massive attention to)--

.btn-light {
  background-color: transparent !important;
  color: var(--dark) !important;
  border: none;
  padding: 6px;
  box-shadow: none;
}

.btn-light:hover {
  background-color: var(--body) !important;
  color: var(--dark) !important;
  border: none;
  box-shadow: none;
}

.btn-light:focus {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.btn-light:active {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

.btn-light:disabled {
  background-color: transparent !important;
  color: var(--dark) !important;
  border: none;
  box-shadow: none;
}

// --Dark Buttons --
// --(Completely hide that item is a button)--

.btn-dark {
  background-color: transparent !important;
  color: var(--dark) !important;
  border: none;
  padding: 6px;
  box-shadow: none;
  outline: none !important;
}

.btn-dark:focus {
  box-shadow: none !important;
  outline: none !important;
}

// --Link Buttons --
// --(Completely hide that item is a button)--

.btn-link {
  color: var(--primary) !important;
  box-shadow: none !important;
  outline: none !important;
  padding: 6px;
}

// --Accordion Buttons --

.accordion-button {
  background-color: var(--light);
  color: var(--dark);
  box-shadow: none;
}

.accordion-button:not(.collapsed):hover {
  background-color: var(--body) !important;
  color: var(--primary);
  box-shadow: none;
}

.accordion-button.collapsed:hover {
  background-color: var(--body) !important;
  color: var(--primary);
  box-shadow: none;
}

.accordion-button:focus {
  color: var(--dark);
  background-color: transparent !important;
  box-shadow: none;
}

.accordion-button:not(.collapsed) {
  color: var(--primary);
  background-color: transparent !important;
  box-shadow: none;
}

.accordion-button:not(.collapsed):active {
  color: var(--primary);
  background-color: transparent !important;
  box-shadow: none;
}

.accordion-button:not(.collapsed):focus {
  color: var(--primary);
  background-color: transparent !important;
  box-shadow: none;
}

.accordion-button::after {
  background-image: none;
}

.accordion-button:not(.collapsed)::after {
  background-image: none;
}

.accordion-item {
  background-color: var(--light) !important;
}

.accordion-body {
  border-top: 1px solid var(--cardBorderBottom);
}
