@import '../../../scss/variables.scss';

@keyframes slide-out {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(650px);
  }
}

@keyframes slide-in {
  0% {
    transform: translateX(650px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes loading {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.menu-container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  position: absolute;
  top: 90px;
  right: 0;
  z-index: 1000;
}

.collapse-container {
  display: flex;
  margin-left: 20px;
  max-height: 168px;
}

.info-container {
  animation: slide-in 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

.info-container-hide {
  animation: slide-out 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

.collapse-card {
  border-radius: 1rem 0 0 1rem;
  padding: 8px;
  border: none !important;
  box-shadow: var(--box-shadow-right);
  cursor: pointer;
  z-index: 1000;
}

.title-bar {
  white-space: nowrap;
  transition: max-width 0.5s ease-in-out;
  background-color: var(--body);
}

.objects-bar {
  background-color: var(--body);
}

.title-bar:hover {
  max-width: 100vw;
  transition: max-width 0.5s ease-in-out;
}

h4.text:hover {
  max-width: 100vw;
  transition: max-width 0.5s ease-in-out;
  transition-delay: 0.5s;
}

h4.text {
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 15vw;
  transition: max-width 0.5s ease-in-out;
}

.search-bar {
  margin-left: 8px;
  max-width: 0%;
  transition: max-width 0.5s ease-in-out;
}

.search-bar-expand {
  margin-left: 8px;
  max-width: 100%;
  transition: max-width 0.5s ease-in-out;
}

.stat-wrapper {
  display: flex;
  justify-content: space-between;
  border: none !important;
  padding: none !important;
  background-color: transparent !important;
  cursor: pointer;
}

.object-list {
  background-color: var(--body);
  max-height: 0;
  max-width: 40vw;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}

.object-list-expand {
  background-color: var(--body);
  max-height: 35vh;
  max-width: 40vw;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}

.list-item {
  padding: 0.1rem 0.8rem;
  margin: 0.25rem auto;
  width: 100%;
  border: 1px solid var(--secondary);
  border-radius: 50px;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;

  i {
    visibility: hidden;
  }

  &:hover {
    border: 1px solid var(--primary);
    cursor: pointer;

    i {
      visibility: visible;
    }
  }
}

.object-wrapper {
  background-color: var(--body);
  padding: 10px;
  margin: 15px;
  overflow-y: scroll;
}

.object-wrapper-expand {
  background-color: var(--body);
  padding: 10px;
  margin: 15px;
  overflow-y: scroll;
}

.asset-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 20vw;
}

#loading {
  animation: loading 3s linear infinite;
}
