@import '../../scss/variables.scss';

.invite-btn,
.upload-btn {
  grid-gap: 15px;
}

.role-select,
.remove-user {
  height: fit-content;
}
