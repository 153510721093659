@import '../../../../../scss/variables.scss';

.add-beacon-container {
  & i.sonarIcon-search {
    color: var(--dark);
  }

  & .beacon-asset-btn {
    width: 200px;
  }

  & .divider {
    border-left: 1px solid var(--secondary);
    height: 100px;
    margin: 0 70px;
  }
}

.paginatedSearch-items {
  & .attached-text {
    color: var(--alt1);

    & .asset-name {
      color: var(--c-primary);
    }
  }

  & .item-name,
  .device-id {
    color: black;
  }
}

.next-btn:disabled,
.next-btn[disabled] {
  color: var(--white);
  background-color: var(--c-primary);
  border: 1px solid var(--c-primary);
}

.next-btn:disabled:hover,
.next-btn[disabled]:hover {
  color: var(--c-primary);
  background-color: var(--white);
  border: 1px solid var(--c-primary);
}

.next-btn {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.next-btn:hover {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}
