@import '../../../../../scss/variables.scss';
@import '../../../../../scss/animations/animations.scss';

.add-beacon-success {
  animation: 2s fade-in ease-out;

  & .success {
    color: var(--dark);
    font-weight: 400;
  }

  & .btn-primary {
    width: 200px;
  }
}

.next-btn:disabled,
.next-btn[disabled] {
  color: var(--white);
  background-color: var(--c-primary);
  border: 1px solid var(--c-primary);
}

.next-btn:disabled:hover,
.next-btn[disabled]:hover {
  color: var(--c-primary);
  background-color: var(--white);
  border: 1px solid var(--c-primary);
}

.next-btn {
  color: var(--white);
  background-color: var(--primary);
  border: 1px solid var(--primary);
}

.next-btn:hover {
  color: var(--primary);
  background-color: var(--white);
  border: 1px solid var(--primary);
}
