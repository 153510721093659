@import '../../scss/variables.scss';
@import '../../scss/animations/animations.scss';

.change-password-form {
  & h2 {
    color: var(--primary);
  }

  & p {
    color: var(--dark);
  }

  & input {
    // margin-bottom: 20px;
  }

  & .form-text {
    color: var(--dark);
    margin-bottom: 8px;
  }

  & .fw-bold {
    letter-spacing: 2px;
  }

  & button#update-pw {
    width: inherit;
    background-color: var(--primary);
    cursor: pointer;

    &:disabled {
      cursor: default;
    }
  }

  & .circle-anim {
    margin-right: 0;
    margin-left: 10px;
    position: absolute;

    &.danger {
      background-color: var(--danger);
      border: 2px solid var(--danger);
    }

    & .x-mark {
      font-size: 16px;
      position: relative;
      color: white;
      width: 0.6em;
      height: 0.1em;

      &::before {
        content: '';
        height: 0.2em;
        width: 0.9em;
        top: 0.43em;
        left: 0.07em;
        border-radius: 3px;
        background: white;
        position: absolute;
        transform: rotate(-45deg);
        animation-duration: 0.2s;
        animation-timing-function: ease;
        animation-name: drawmark;
        // transform: scaleX(-1) rotate(135deg);
      }

      &::after {
        content: '';
        height: 0.2em;
        width: 0.9em;
        top: 0.43em;
        left: 0.07em;
        border-radius: 3px;
        background: white;
        position: absolute;
        transform: rotate(45deg);
        animation-duration: 0.2s;
        animation-timing-function: ease;
        animation-name: drawmark;
      }
    }
  }

  & .pw-error {
    color: var(--danger);
    font-weight: 400;
  }
}

@keyframes drawmark {
  0% {
    width: 0.1em;
    opacity: 1;
  }

  20% {
    width: 0.3em;
    opacity: 1;
  }

  40% {
    width: 0.6em;
    opacity: 1;
  }

  100% {
    width: 0.9em;
    opacity: 1;
  }
}

.new-user-change-pw {
  background: linear-gradient(300deg, #10b9cc, #0147fb, #0147fb);
  height: inherit;

  & .background-img {
    background-image: url('../../img/network-texture.png');
    height: inherit;
    width: 100%;
  }

  & .container {
    padding-top: 40px;
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;

    &.fade-in {
      animation: fade-and-slide 1s ease-in-out;
    }
  }

  & .card {
    background: white;
    box-shadow: none;
    padding: 40px;
    width: 500px;

    & .col {
      width: 100% !important;
    }
  }

  & .above-txt-section {
    & h5 {
      margin-left: 40px;
    }

    & img {
      margin-left: 80px;
    }

    & p {
      padding-top: 20px;
    }

    color: white;

    & .logo {
      width: 200px;
    }
  }
}
