@import '../../scss/variables.scss';

.center-container {
  position: relative;
  bottom: 60px;
  left: 20px;
  width: 40px;
  z-index: 1000;
}

.center-button {
  padding: 8px;
  border: none !important;
  background-color: var(--body) !important;
}

.leaflet-container {
  background-color: white !important;
}
